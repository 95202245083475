import { rangeStep } from "ramda-adjunct";
import {
	useEffect,
	useRef,
	useState,
	useContext,
	useLayoutEffect,
} from "react";

import { concatAll, of, Subject, switchMap, tap } from "rxjs";
import {
	EquirectangularReflectionMapping,
	Mesh,
	MeshPhysicalMaterial,
	PerspectiveCamera,
	Scene,
	SphereGeometry,
	WebGLRenderer,
} from "three";

import NFTBox from "../src/components/nft_box/index";
import { RGBELoader } from "three/addons/loaders/RGBELoader";
import SectionBox1 from "./components/section1";
import Navbar from "./components/section1/Navbar";
import SectionsBox2 from "./components/section2";
import SectionBox3 from "./components/section3";
import SectionBox4 from "./components/section4";
import SectionBox5 from "./components/section5";
import Footer from "./components/section6";

import {
	DivAppBoxSC,
	DivAppRelativeBoxSC,
} from "./styled-components/styled.app";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
	const bottomRefCollection = useRef(null);
	const bottomRefBox = useRef(null);
	const bottomRefHome = useRef(null);
	const [innerWidth, setInnerWidth] = useState(window.innerWidth);
	const onClickScrollCollection = () => {
		bottomRefCollection.current?.scrollIntoView({ behavior: "smooth" });
	};
	const onClickScrollBox = () => {
		bottomRefBox.current?.scrollIntoView({ behavior: "smooth" });
	};
	const onClickScrollHome = () => {
		bottomRefHome.current?.scrollIntoView({ behavior: "smooth" });
	};

	// useEffect(() => {
	// 	window.addEventListener("resize", (event) => {
	// 		setInnerWidth(event.target.innerWidth);
	// 		console.log('sdfs', event.target.innerWidth);
	// 	});
	// }, []);

	let bubbles_initialized = false;

	function InitBubbles() {
		if (bubbles_initialized) return;
		bubbles_initialized = true;

		const renderer_width = document.documentElement.clientWidth;
		const renderer_height = document
			.getElementById("root")
			.getBoundingClientRect().height;
		const scene = new Scene();
		const camera = new PerspectiveCamera(
			45,
			renderer_width / renderer_height,
			1,
			32
		);
		camera.position.set(0, 0, 30);

		const renderer = new WebGLRenderer({
			antialias: true,
			alpha: true,
			opacity: 0,
		});

		renderer.setSize(renderer_width, renderer_height);
		document.getElementById("canvas").appendChild(renderer.domElement);

		const envMap = new RGBELoader()
			.setPath("/")
			.load("royal_esplanade_1k.hdr", () => {
				envMap.mapping = EquirectangularReflectionMapping;
			});

		const black_material = new MeshPhysicalMaterial({
			color: 0x000000,
			clearcoat: 1,
			metalness: 1,
			roughness: 1,
			specularColor: 0xffffff,
			envMap,
			envMapIntensity: 1,
		});

		const pre_render$ = new Subject();

		let width = Math.floor(renderer_width / 90);
		let height = Math.floor(renderer_height / 90);
		let half_width = width / 2;
		let half_height = height / 2;

		const spheres = [];

		for (let x of rangeStep(2.5, 0, width)) {
			for (let y of rangeStep(1, 0, height)) {
				if (Math.random() > 0.9) {
					let size = Math.random();
					const geometry = new SphereGeometry(
						0.1 < size > 0.9 ? size : 0.1,
						64,
						64
					);
					const sphere = new Mesh(geometry, black_material);
					scene.add(sphere);

					sphere.position.x = Math.random() * (x - half_width);
					sphere.position.y = Math.random() * (y - half_height);
					sphere.position.z = Math.random() * 4;

					spheres.push(sphere);
				}
			}
		}

		pre_render$
			.pipe(switchMap(() => of(spheres)))
			.pipe(concatAll())
			.pipe(
				tap((sphere) => {
					sphere.position.x +=
						Math.random() > 0.5
							? 0.001 * Math.random()
							: -0.001 * Math.random() + 0.002;
					sphere.position.y +=
						Math.random() > 0.5
							? 0.001 * Math.random()
							: -0.001 * Math.random() + 0.002;
					sphere.position.z +=
						Math.random() > 0.5
							? 0.001 * Math.random()
							: -0.001 * Math.random();
				})
			)
			.subscribe();

		function animate(t) {
			pre_render$.next(t);
			requestAnimationFrame(animate);
			renderer.render(scene, camera);
		}

		window.onresize = function () {
			camera.aspect =
				window.innerWidth /
				document.getElementById("root").getBoundingClientRect().height;
			camera.updateProjectionMatrix();

			renderer.setSize(
				window.innerWidth,
				document.getElementById("root").getBoundingClientRect().height
			);
		};

		animate();
	}

	useEffect(() => {
		let width = window.innerWidth;
		if (width >= 768) {
			InitBubbles();
			window.addEventListener("resize", InitBubbles);
		}
	}, []);

	return (
		<>
			<ToastContainer
				style={{ top: 95 }}
				position="top-right"
				autoClose={5000}
				closeOnClick
			/>

			<div
				id="canvas"
				style={{ position: "absolute", top: "0", left: "0" }}
			></div>
			<DivAppRelativeBoxSC>
				<DivAppBoxSC>
					<Navbar
						onClickButtonCollection={onClickScrollCollection}
						onClickButtonBox={onClickScrollBox}
						onClickButtonHome={onClickScrollHome}
					/>

					<SectionBox1 bottomRefHome={bottomRefHome} />
					<SectionsBox2 />
					<SectionBox4
						bottomRefBox={bottomRefBox}
						onClickButtonHome={onClickScrollHome}
					/>

					<SectionBox3 />
					<SectionBox5 bottomRefCollection={bottomRefCollection} />
					<Footer />
				</DivAppBoxSC>
			</DivAppRelativeBoxSC>
		</>
	);
}

export default App;
