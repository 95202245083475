import styled from "styled-components/macro";
import { HiX } from "react-icons/hi";

export const DivBoxGallerySC = styled.div`
	//margin-top: 100px;
	height: calc(100vh - 100px);
`;

export const IoIosArrowBackSC = styled(HiX)`
	position: fixed;
	top: 70px;
	right: 10px;
	width: 2rem;
	height: 2rem;
	padding: 5px;
	background-color: rgba(0, 0, 0, 0.4);
	color: #ffffff;
	cursor: pointer;
`;

export const DivBoxCenterSC = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	justify-content: center;
`;
