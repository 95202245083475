import styled, { keyframes } from "styled-components/macro";
import Box from "../img/GoldCube.png";
import Modal from "react-modal";
import { HiX } from "react-icons/hi";
import { FaEthereum } from "react-icons/fa";
export const DivWrapSC = styled.div`
	// width: 100vw;
	display: grid;
	position: relative;
	min-height: calc(100vh - 320px);
	@media (max-width: 480px) {
		min-height: calc(100vh - 260px);
	}
	//padding-top: 160px;
`;

const rotate = keyframes`
  0%{
	filter: blur(1.3rem);
	background-color: lightgray;
  }
  50%{
	
	background-color: gray;
	filter: blur(1.4rem);
  }
  100% {
    filter: blur(1.5rem);
	background-color: darkgray;
  }
`;
export const DivShadow = styled.div`
  animation: ${rotate} 2s linear infinite;
	
	height: 455px;
	position: absolute;
opacity: .25;
width: 427px;
left: calc(50% - 220px);
top: calc(50% - 330px);
animation-direction: alternate-reverse;
@media(max-width:800px){
	height: 398px;
	top: calc(50% - 200px);
	
width: 361px;

}
@media(max-width:450px){
	height: 254px;
top: calc(50% - 130px);
left: calc(50% - 110px);
width: 215px;
}



	
`;

export const ButtonPrevModal = styled.div`
	height: 40px;
	display: flex;
	border: none;
	width: 100%;
	border-radius: 30px;

	justify-content: flex-end;
`;
export const IoIosArrowBackSC = styled(HiX)`
	width: 45px;
	height: 40px;
	opacity: 60%;
	position: relative;
	right: 10px;
	top: 10px;
	color: white;
	cursor: pointer;
`;
export const DivWrapModal = styled.div`
	display: grid;
	min-width: 360px;

	background: #000000;
	border-radius: 30px;
	z-index: 1000;

	width: 100%;
	height: 100%;
	border-radius: 0px;
	align-content: flex-start;
	z-index: 100000;
	margin-top: 0px;
	gap: 125px;
	@media (max-width: 809px) {
		gap: 0px;
	}
	@media (max-height: 800px) {
		gap: 0px;
	}
`;
export const BlockModal = styled(Modal)`
	font-family: "Orbitron" !important;
	z-index: 1000;
	background: rgba(0, 0, 0, 0.3);
	width: 100%;
	height: 100%;
	display: grid;
	justify-content: center;
	align-content: center;
	min-width: 360px;
	max-height: 100vh;

	z-index: 10000;
	align-content: unset;
	justify-content: unset;

	@media (max-width: 809px) {
		overflow-y: scroll;
	}
	@media (max-height: 800px) {
		overflow-y: scroll;
	}
`;

export const DivS4BoxSC = styled.div`
	width: 100%;
	min-height: 813px;
	background: #0f0f0f;
	display: grid;
	justify-items: center;
	align-items: center;
	// border: 1px solid green;
	@media (max-width: 1000px) {
		height: 1149px;
	}
	@media (max-width: 480px) {
		height: 980px;
	}
	@media (max-width: 360px) {
		height: 850px;
	}
`;
export const DivInnerBoxTwoColumnsSC = styled.div`
	max-width: 1420px;
	// border: 1px solid red;
	display: grid;
	grid-template-columns: 40% 60%;
	gap: 10px;
	width: 80vw;
	height: 600px;
	@media (max-width: 1000px) {
		align-content: center;
		grid-template-columns: 100%;
		grid-template-rows: max-content max-content;
		height: 900px;
	}
	@media (max-width: 768px) {
		width: 95vw;
	}
	@media (max-width: 430px) {
		height: 800px;
	}
`;
export const DivNFTBlockSC = styled.div`
	// border: 1px solid blue;
	display: grid;
	grid-template-rows: max-content max-content;
	gap: 30px;
	align-content: center;
	max-height: 560px;
	@media (max-width: 1000px) {
		height: 100%;
		grid-row: 2 / 2;
		max-height: 960px;
	}
`;
export const DivNFTBoxSC = styled.div`
	// border: 1px solid white;
	width: 450px;
	height: 450px;
	background-size: 80%;

	background-image: url(${Box});

	background-repeat: no-repeat;
	background-position: bottom;
	max-width: 550px;
	max-height: 526px;
	display: grid;
	justify-self: center;
	@media (max-width: 1700px) {
		width: 350px;
		height: 350px;
	}
	@media (max-width: 1000px) {
		background-size: 100%;
		width: 400px;
		height: 400px;
	}
	@media (max-width: 768px) {
		width: 400px;
		height: 400px;
	}
	@media (max-width: 480px) {
		background-size: 90%;
		width: 350px;
		height: 350px;
	}
`;
export const DivNFTBoxButtonSC = styled.div`
	// border: 1px solid pink;
	display: grid;
	justify-items: center;
	align-items: start;
`;
export const DivNFTTextSC = styled.div`
	// border: 1px solid yellow;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: clamp(15px, 2vw, 36px);
	line-height: clamp(15px, 2.5vw, 62px);
	color: #ffffff;
	text-align: left;
	display: grid;
	align-self: center;
	max-width: clamp(825px, 2vw, 480px);

	@media (max-width: 1000px) {
		font-size: clamp(20px, 5vw, 32px);
		height: 100%;
		grid-row: 1 / 2;
		text-align: center;
		line-height: 42px;
	}
	@media (max-width: 480px) {
		line-height: 32px;
	}
`;
export const DivCircleGradientSC = styled.div`
	position: absolute;
	background: linear-gradient(
		81.97deg,
		rgba(255, 0, 0, 0.35) 32.54%,
		rgba(254, 126, 0, 0.35) 39.05%,
		rgba(255, 230, 0, 0.35) 48.84%,
		rgba(0, 255, 1, 0.35) 53.99%,
		rgba(0, 194, 255, 0.35) 59.49%,
		rgba(0, 0, 254, 0.35) 65.29%,
		rgba(204, 0, 255, 0.35) 87.4%
	);
	opacity: 0.8;
	filter: blur(140px);
	transform: rotate(-5.95deg);
	width: clamp(400px, 50%, 900px);
	height: 232.48px;
	margin-top: 60px;
	@media (max-width: 1000px) {
		width: 80%;
	}
`;
export const ButtonSC = styled.button`
	display: grid;
	justify-content: center;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 18px 36px;
	gap: 10px;
	width: clamp(256px, 60%, 352px);
	height: clamp(58px, 4vw, 78px);
	opacity: 1;
	background: linear-gradient(
		81.97deg,
		#1d4eff 0%,
		#ff44b4 49.73%,
		#ffd600 100%
	);
	box-shadow: 0px 12px 100px rgba(242, 68, 184, 0.4);
	border-radius: 12px;
	border: none;
	cursor: pointer;
	transition: 3ms;
	/* :hover {
		opacity: 0.95;
		scale: 0.98;
	}
	:active {
		opacity: 1;
		scale: 0.96;
	}
	transition: 0.3s; */
`;
export const TextButtonSC = styled.div`
	align-self: center;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: clamp(20px, 1.7vw, 28px);
	line-height: 50px;
	color: #ffffff;
	flex: none;
	order: 0;
	flex-grow: 0;
`;

export const StyledFaethereum = styled(FaEthereum)`
	margin-bottom: -4px;
`;
