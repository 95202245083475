import React, { useContext, useState, useEffect } from "react";
import {
	DivBoxContentNavSC,
	DivBoxLinksSC,
	DivBoxLogoSC,
	DivLinkSC,
	DivNavbarSC,
	LinkLogoSC,
	LogoSC,
} from "../../styled-components/styled.navbar";
import "./Sidebar.css";
import GlobalDispatchContext from "../../global_dispatch_context";
const Navbar = (props) => {
	const {onClickButtonHome, onClickButtonBox, onClickButtonCollection} = props;
	const { state, dispatch } = useContext(GlobalDispatchContext);
    const { isOpen, } = state;
	return (
		<>
		{!isOpen?(<><DivNavbarSC>
				<DivBoxContentNavSC>
					<LinkLogoSC href="/">
						<DivBoxLogoSC>
							<LogoSC/>
						</DivBoxLogoSC>
					</LinkLogoSC>
					<div className="hamburger-menu">
				<input id="menu__toggle" type="checkbox"/>
				<label className="menu__btn" htmlFor="menu__toggle">
					<span></span>
				</label>
				<ul className="menu__box">
					<li>
					<input id="menu__toggle" type="checkbox"/>
				<label className="menu__item" htmlFor="menu__toggle" onClick={ onClickButtonHome }>

					Home
				</label>



					</li>
					<li>
					<input id="menu__toggle" type="checkbox"/>
				<label className="menu__item" htmlFor="menu__toggle" onClick={ onClickButtonBox }>

					Card
				</label>


					</li>
					<li>
					<input id="menu__toggle" type="checkbox"/>
				<label className="menu__item" htmlFor="menu__toggle" onClick={ onClickButtonCollection }>

					Collection
				</label>


					</li>
				</ul>
			</div>

					<DivBoxLinksSC>
						<DivLinkSC onClick={ onClickButtonHome }>Home</DivLinkSC>
						<DivLinkSC onClick={ onClickButtonBox }>Box</DivLinkSC>
						<DivLinkSC onClick={ onClickButtonCollection }>Collection</DivLinkSC>
					</DivBoxLinksSC>
				</DivBoxContentNavSC>

			</DivNavbarSC>
			</>):null}
		</>
	);
};

export default Navbar;
