import {CoinbaseWalletSDK} from "@coinbase/wallet-sdk";


import {propEq} from "ramda";
import React, {useEffect, useState} from "react";

// import WalletModal from "../wallet-modal/WalletModal";
import {useDispatch, useSelector} from "react-redux";
import {toast} from 'react-toastify';
import {BehaviorSubject, bufferCount, concatAll, filter, pluck, skipUntil, Subject, tap, withLatestFrom} from 'rxjs'
import {
	AmbientLight,
	BoxGeometry,
	DirectionalLight,
	Mesh,
	MeshBasicMaterial,
	MeshLambertMaterial,
	PCFSoftShadowMap,
	PerspectiveCamera,
	PlaneGeometry,
	PointLight,
	Scene,
	SphereGeometry,
	TextureLoader,
	WebGLRenderer
} from 'three'
import {OBJLoader} from "three/addons/loaders/OBJLoader";
import loadingImg from '../../img/loading.svg'
import {connectMetamask} from "../../redux/blockchain/blockchainActions";
import {
	ButtonSC,
	DivBoxBoxNFTSC,
	DivBoxContentSC,
	DivBoxNFT1500SC,
	DivBoxNFTSC,
	DivBoxSC,
	DivBoxTextTitleSC,
	DivGradientSC,
	DivTextBodySC,
	DivTextTitleSC,
	TextButtonSC,
} from "../../styled-components/styled.section1";
import { StyledFaethereum } from "../../styled-components/styled.section4";
import './index.scss'


let animate$ = new BehaviorSubject(null)

let selected_nft = ''
let box_initialized = false

function SelectNFT(nft) {
	selected_nft = nft
}

const SectionBox1 = (props) => {


	const {bottomRefHome} = props;
	const [open, setOpen] = useState(false)
	const [claimingNft, setClaimingNft] = useState(false)

	const [saleActive, setSaleActive] = useState(null)
	const [cost, setCost] = useState(null)

	const [finishedLoading, setFinishedLoading] = useState(null)
	const [mintAmount, setMintAmount] = useState(1)
	const [totalSupply, setTotalSupply] = useState(null)

	const blockchain = useSelector((state) => state.blockchain);
	const dispatch = useDispatch();


	const [CONFIG, SET_CONFIG] = useState({
		CONTRACT_ADDRESS: "",
		NETWORK: {
			NAME: "",
			SYMBOL: "",
			ID: 0,
		},
		GAS_LIMIT: 0,
	});
	const getConfig = async () => {
		const configResponse = await fetch("/config/config.json", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		});
		const config = await configResponse.json();
		SET_CONFIG(config);
	};

	useEffect(() => {
		getConfig();

	}, []);

	const initConnect = async () => {
		let active = await blockchain.smartContract.publicM()
		let price = await blockchain.smartContract._price()
		let totalSupply = await blockchain.smartContract.totalSupply()

		setSaleActive(active)
		setCost(price)
		setFinishedLoading(true)
		setTotalSupply(parseInt(totalSupply._hex, 16))
		// console.log(active)
		console.log(totalSupply)
	}
	useEffect(() => {
		if (blockchain.smartContract === null) return
		initConnect()
	}, [blockchain])

	const decrementMintAmount = () => {
		let newMintAmount = mintAmount - 1;
		if (newMintAmount < 1) {
			newMintAmount = 1;
		}
		setMintAmount(newMintAmount);
	};

	const incrementMintAmount = () => {
		let newMintAmount = mintAmount + 1;
		if (newMintAmount > 250) {
			newMintAmount = 250;
		}
		setMintAmount(newMintAmount);
	};

	const setMintAmountInput = (amount) => {
		console.log(amount)
		amount = Number(amount)
		if (amount > 250) {
			amount = 250
		}
		setMintAmount(amount)
	};

	const mint = async () => {
		try {
			let gasLimit = CONFIG.GAS_LIMIT;
			let totalCostWei = String(cost * mintAmount);
			let totalGasLimit = String(gasLimit * mintAmount);

			setClaimingNft(true);
			toast.info(`Minting your ${ mintAmount > 1 ? 'Modern Art Theory NFTs' : 'Modern Art Theory NFT' }...`);

			const transaction = await blockchain.smartContract
				.publicSaleMint(mintAmount, {
					// gasLimit: String(totalGasLimit),
					// from: blockchain.account,
					value: totalCostWei,
				})


			const receipt = await transaction.wait()

			// console.log(receipt)


			setClaimingNft(false);

			setMintAmount(1)
			let tokenId = await blockchain.smartContract.totalSupply()
			let currentTokenId = parseInt(tokenId._hex, 16)

			toast.success(() =>
				<span>
					Modern Art Theory NFT Mint Successful! Visit <a href={`https://opensea.io/assets/ethereum/0xf4149ebb6c0a07bf619405e79ba378941d4007dc/${currentTokenId}`}>opensea.io/collection/modernarttheory</a> to view your NFT.
				</span>
			);
			// console.log(ethers.BigNumber.from(tokenId._hex))
			let base = '000'
			base = base.substring(0, base.length - String(currentTokenId).length) + String(currentTokenId)
			console.log('base', base)

			let path = `/nft-imgs/${ base }.png`
			SelectNFT(path)
			document.getElementById('nft_box_canvas').classList.remove('nft_hidden')
			document.getElementById('nft_box_canvas').classList.add('nft_show')
			setTimeout(() => {
				animate$.next(1)
			}, 1000)

		} catch (e) {
			console.log(e.message.substring(0,18))
			if(e.message.includes('insufficient funds')){
				toast.error(e.message.substring(0,18))
			}

			setClaimingNft(false);

		}
	};


	return (
		<>
			{ " " }
			{/* <WalletModal open={open} setOpen={setOpen} /> */ }
			<div ref={ bottomRefHome }></div>
			<DivBoxSC>

				<DivBoxBoxNFTSC>
					<DivBoxContentSC>
						<div>
							<DivGradientSC/>
							<DivBoxTextTitleSC>
								<DivTextTitleSC>Exclusive Access</DivTextTitleSC>
								<DivTextBodySC>
									Your MAT NFT provides you access to the most exclusive events
									of Art Basel and beyond.
								</DivTextBodySC>
								{
									blockchain.smartContract === null &&
									<ButtonSC onClick={() => dispatch(connectMetamask()) }>
										<TextButtonSC>
											{/* {walletAdress !== "" ? "Connected" : "Connect wallet"} */ }
											Connect wallet

										</TextButtonSC>
									</ButtonSC>
								}
								{
									blockchain.smartContract !== null && finishedLoading !== true &&
									<ButtonSC>
										<img src={ loadingImg }/>
									</ButtonSC>
								}
								{
									saleActive === false &&
									<ButtonSC>
										<TextButtonSC>
											Coming Soon
										</TextButtonSC>
									</ButtonSC>
								}
								{
									saleActive === true &&
									<div className="mint-wrapper">
										<span>{totalSupply}/250</span>
										<div className="mint-amount-wrapper">
											<button onClick={ () => {
												decrementMintAmount()
											} }>-
											</button>
											<input value={ mintAmount } onChange={ (e) => {
												setMintAmountInput(e.target.value)
											} }/>
											<button onClick={ () => {
												incrementMintAmount()
											} }>+
											</button>
										</div>
										<ButtonSC onClick={ () => {
											mint()
										} }>
											{
												!claimingNft ?
													<TextButtonSC>
														MINT {mintAmount * 0.5} <StyledFaethereum/>
													</TextButtonSC>
													:
													<img src={ loadingImg }/>
											}

										</ButtonSC>
									</div>
								}
							</DivBoxTextTitleSC>
						</div>
					</DivBoxContentSC>
					<DivBoxNFTSC/>
					<DivBoxNFT1500SC/>
				</DivBoxBoxNFTSC>
			</DivBoxSC>
		</>
	);
};

export default SectionBox1;
