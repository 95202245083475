import React from "react";
//  import ScrollUpButton from 'react-scroll-up-button';
import {
	DivContentBoxSC,
	DivCopyrightBoxSC,
	DivFooterBoxSC,
	DivMailBoxSC,
	DivSocialNetworkBoxSC,
	DivSocialNetworkSC,
	H1CopyrightBoxSC,
	H1MailBoxSC,
	IconLinkFacebookSC,
	IconLinkInstagramSC,
	IconLinkMailSC,
	IconLinkTwitterSC,
	LinkSocialNetworkSC,
	DivInstagramBoxSC,
	LogoSC,
	DivAdaptivePhone,
	DivAdaptivePC,
	DivMadeByBoxSC,
	DivMadeByLogoSC,
	GridTextMadeBySC,
	PTextMadeBySC,
	PTextCompanySC,
	DivMadeByBoxAdaptiveSC
} from "../../styled-components/styled.section6-footer";
import { AiOutlineArrowUp } from "react-icons/ai";
const SectionBox6 = (props) => {
	return (
		<>
			<DivFooterBoxSC id="footer-12idjkgjewgj">
				<DivContentBoxSC>
					<DivMadeByBoxAdaptiveSC>
						<LinkSocialNetworkSC href="https://www.cyberzenlabs.com/">
							<DivMadeByLogoSC />
						</LinkSocialNetworkSC>
						<GridTextMadeBySC>
							<PTextMadeBySC>made by</PTextMadeBySC>
							<PTextCompanySC>Cyberzen Labs</PTextCompanySC>
						</GridTextMadeBySC>
					</DivMadeByBoxAdaptiveSC>
					<DivSocialNetworkBoxSC>
						<DivSocialNetworkSC>
							{/* <LinkSocialNetworkSC href="#">
								<IconLinkFacebookSC />
							</LinkSocialNetworkSC>
							<LinkSocialNetworkSC href="#">
								<IconLinkTwitterSC />
							</LinkSocialNetworkSC>  */}
							<DivMailBoxSC>
								<LinkSocialNetworkSC href="#">
									<LogoSC />
								</LinkSocialNetworkSC>
								<H1MailBoxSC></H1MailBoxSC>
							</DivMailBoxSC>

							<DivMailBoxSC>
								<LinkSocialNetworkSC href="https://www.instagram.com/modernarttheorygallery/">
									<IconLinkInstagramSC />
								</LinkSocialNetworkSC>
								<H1MailBoxSC>instagram</H1MailBoxSC>
							</DivMailBoxSC>

							<DivMailBoxSC>
								<LinkSocialNetworkSC href="#">
									<IconLinkMailSC />
								</LinkSocialNetworkSC>
								<H1MailBoxSC>
									EMAIL US<br></br> Anders@modernarttheory.io
								</H1MailBoxSC>
							</DivMailBoxSC>
							<DivMadeByBoxSC>
								<LinkSocialNetworkSC href="https://www.cyberzenlabs.com/">
									<DivMadeByLogoSC />
								</LinkSocialNetworkSC>
								<GridTextMadeBySC>
									<PTextMadeBySC>made by</PTextMadeBySC>
									<PTextCompanySC>Cyberzen Labs</PTextCompanySC>
								</GridTextMadeBySC>
							</DivMadeByBoxSC>
						</DivSocialNetworkSC>
					</DivSocialNetworkBoxSC>
					<DivCopyrightBoxSC>
						<H1CopyrightBoxSC>
							Copyright 2022 Modern Art Theory. All Rights
							Reserved.
						</H1CopyrightBoxSC>
					</DivCopyrightBoxSC>
				</DivContentBoxSC>
			</DivFooterBoxSC>

			{/* <DivAdaptivePC>	<ScrollUpButton
				StopPosition={ 0 }
				ShowAtPosition={ 650 }
				EasingType="easeOutCubic"
				AnimationDuration={ 500 }
				ContainerClassName="ScrollUpButton__Container"
				TransitionClassName="ScrollUpButton__Toggled"
				ToggledStyle={ {
					right: 40,
					opacity: 0.8,
					WebkitTransition: 'all 0.5s ease-in-out',
					transition: 'all 0.5s ease-in-out',
					transitionProperty: 'opacity, right, boxShadow',
				} }
				style={ {
					backgroundColor: 'black',
					borderRadius: '50%',
					border: 'none',
					height: 70,
					position: 'fixed',
					bottom: 40,
					width: 70,
					WebkitTransition: 'all 0.5s ease-in-out',
					transition: 'all 0.5s ease-in-out',
					transitionProperty: 'opacity, right, boxShadow',
					cursor: 'pointer',
					opacity: 0,
					right: -75,
					boxShadow: '0px 0px 8px white',
					display: 'grid',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize: '35px',
			} }
			>
				<AiOutlineArrowUp color="white"/>
			</ScrollUpButton></DivAdaptivePC>

			<DivAdaptivePhone>
				<ScrollUpButton
				StopPosition={ 0 }
				ShowAtPosition={ 200 }
				EasingType="easeOutCubic"
				AnimationDuration={ 500 }
				ContainerClassName="ScrollUpButton__Container"
				TransitionClassName="ScrollUpButton__Toggled"
				ToggledStyle={ {
					right: 20,
					opacity: 0.8,
					WebkitTransition: 'all 0.5s ease-in-out',
					transition: 'all 0.5s ease-in-out',
					transitionProperty: 'opacity, right, boxShadow',
				} }
				style={ {
					backgroundColor: 'black',
					borderRadius: '50%',
					border: 'none',
					height: 50,
					position: 'fixed',
					bottom: 30,
					width: 50,
					WebkitTransition: 'all 0.5s ease-in-out',
					transition: 'all 0.5s ease-in-out',
					transitionProperty: 'opacity, right, boxShadow',
					cursor: 'pointer',
					opacity: 0,
					right: -75,
					boxShadow: '0px 0px 8px white',
					display: 'grid',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize: '25px',
			} }
			>
				<AiOutlineArrowUp color="white"/>
			</ScrollUpButton>
			<DivAdaptivePC>	<ScrollUpButton
				StopPosition={ 0 }
				ShowAtPosition={ 650 }
				EasingType="easeOutCubic"
				AnimationDuration={ 500 }
				ContainerClassName="ScrollUpButton__Container"
				TransitionClassName="ScrollUpButton__Toggled"
				ToggledStyle={ {
					right: 40,
					opacity: 0.8,
					WebkitTransition: 'all 0.5s ease-in-out',
					transition: 'all 0.5s ease-in-out',
					transitionProperty: 'opacity, right, boxShadow',
				} }
				style={ {
					backgroundColor: 'black',
					borderRadius: '50%',
					border: 'none',
					height: 70,
					position: 'fixed',
					bottom: 40,
					width: 70,
					WebkitTransition: 'all 0.5s ease-in-out',
					transition: 'all 0.5s ease-in-out',
					transitionProperty: 'opacity, right, boxShadow',
					cursor: 'pointer',
					opacity: 0,
					right: -75,
					boxShadow: '0px 0px 8px white',
					display: 'grid',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize: '35px',
			} }
			>
				<AiOutlineArrowUp color="white"/>
			</ScrollUpButton></DivAdaptivePC>

			<DivAdaptivePhone>
				<ScrollUpButton
				StopPosition={ 0 }
				ShowAtPosition={ 200 }
				EasingType="easeOutCubic"
				AnimationDuration={ 500 }
				ContainerClassName="ScrollUpButton__Container"
				TransitionClassName="ScrollUpButton__Toggled"
				ToggledStyle={ {
					right: 20,
					opacity: 0.8,
					WebkitTransition: 'all 0.5s ease-in-out',
					transition: 'all 0.5s ease-in-out',
					transitionProperty: 'opacity, right, boxShadow',
				} }
				style={ {
					backgroundColor: 'black',
					borderRadius: '50%',
					border: 'none',
					height: 50,
					position: 'fixed',
					bottom: 30,
					width: 50,
					WebkitTransition: 'all 0.5s ease-in-out',
					transition: 'all 0.5s ease-in-out',
					transitionProperty: 'opacity, right, boxShadow',
					cursor: 'pointer',
					opacity: 0,
					right: -75,
					boxShadow: '0px 0px 8px white',
					display: 'grid',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize: '25px',
			} }
			>
				<AiOutlineArrowUp color="white"/>
			</ScrollUpButton>
			</DivAdaptivePhone> */}
		</>
	);
};

export default SectionBox6;
