import React, { useContext} from "react";

import GlobalDispatchContext from "../../global_dispatch_context";
import {
  BlockModal,
  ButtonPrevModal,
  DivWrapModal,
  IoIosArrowBackSC,
  DivShadow,
} from "../../styled-components/styled.section4";
import {
  DivWrapSC,
} from "../../styled-components/styled.section4";

import NFTBox from "../nft_box";


const Modal = ({
}) => {
  const { state, dispatch } = useContext(GlobalDispatchContext);
  const { isOpen, modalData } = state;

  return (
    <>
      <BlockModal isOpen={isOpen} contentLabel="Modal">
        <DivWrapModal>
          <ButtonPrevModal>
            <IoIosArrowBackSC
              onClick={() =>
                dispatch({
                  type: "SET_IS_OPEN",
                  status: false,
                })
              }
            />
          </ButtonPrevModal>
          <DivWrapSC>
			  <NFTBox />
      

			  <div
        
				  id="nft_box_canvas"
				  class="nft_hidden"
				  style={{
         
					  position: 'relative',
					  top: 0,
					  left: 0,
					  zIndex: 9998
				  }}
			  >
  <DivShadow/>

			  </div>
          </DivWrapSC>
        </DivWrapModal>
      </BlockModal>
    </>
  );
};

export default Modal;
