import React, { useState } from "react";
import "./gallery.css";
import NFT21 from "../img/007.png";
import NFT22 from "../img/016.png";
import NFT23 from "../img/031.png";
import NFT24 from "../img/045.png";
import NFT25 from "../img/053.png";
import NFT28 from "../img/063.png";
import NFT29 from "../img/065.png";
import NFT36 from "../img/101.png";
import NFT37 from "../img/nft2.png";
import NFT38 from "../img/nft3.png";
import NFT39 from "../img/nft4.png";
import NFT40 from "../img/nft5.png";
import NFT41 from "../img/nft6.png";
import NFT43 from "../img/nft8.png";
import NFT44 from "../img/104.png";
import NFT45 from "../img/129.png";
import NFT46 from "../img/135.png";
import NFT47 from "../img/165.png";
import NFT48 from "../img/191.png";
import NFT49 from "../img/192.png";
import NFT50 from "../img/218.png";
import NFT51 from "../img/221.png";
import NFT52 from "../img/238.png";
import NFT53 from "../img/nft9.png";

import {
	IoIosArrowBackSC,
	DivBoxCenterSC,
} from "../styled-components/slyled.gallery";
import Navbar from "./section1/Navbar";
/* import CloseIcon from "@mui/icons-material/Close"; */
const Gallery = () => {
	let data = [
		{
			id: 1,
			imgSrc: NFT21,
		},
		{
			id: 2,
			imgSrc: NFT22,
		},
		{
			id: 3,
			imgSrc: NFT23,
		},
		{
			id: 4,
			imgSrc: NFT24,
		},
		{
			id: 5,
			imgSrc: NFT25,
		},
		{
			id: 6,
			imgSrc: NFT28,
		},
		{
			id: 7,
			imgSrc: NFT29,
		},
		{
			id: 8,
			imgSrc: NFT36,
		},
		{
			id: 9,
			imgSrc: NFT37,
		},
		{
			id: 10,
			imgSrc: NFT38,
		},
		{
			id: 11,
			imgSrc: NFT39,
		},
		{
			id: 12,
			imgSrc: NFT40,
		},
		{
			id: 13,
			imgSrc: NFT41,
		},
		{
			id: 15,
			imgSrc: NFT43,
		},
		{
			id: 16,
			imgSrc: NFT44,
		},
		{
			id: 17,
			imgSrc: NFT45,
		},
		{
			id: 18,
			imgSrc: NFT46,
		},
		{
			id: 19,
			imgSrc: NFT47,
		},
		{
			id: 20,
			imgSrc: NFT48,
		},
		{
			id: 21,
			imgSrc: NFT49,
		},
		{
			id: 22,
			imgSrc: NFT50,
		},
		{
			id: 23,
			imgSrc: NFT51,
		},
		{
			id: 24,
			imgSrc: NFT52,
		},
		{
			id: 25,
			imgSrc: NFT53,
		},
	];
	const [model, setModel] = useState(false);
	const [tempImgSrc, setTempImgSrc] = useState("");
	const getImg = (imgSrc) => {
		setTempImgSrc(imgSrc);
		setModel(true);
	};
	return (
		<DivBoxCenterSC>
			<div
				className={model ? "model open" : "model"}
				onClick={() => setModel(false)}
			>
				<img
					src={tempImgSrc}
					className="img-nft"
					onClick={(e) => e.stopPropagation()}
				/>
				<IoIosArrowBackSC onClick={() => setModel(false)} />
			</div>
			<div className="gallery">
				{data.map((item, index) => {
					return (
						<div
							className="pics"
							key={index}
							onClick={() => getImg(item.imgSrc)}
						>
							<img src={item.imgSrc} style={{ width: "100%" }} />
						</div>
					);
				})}
			</div>
		</DivBoxCenterSC>
	);
};

export default Gallery;
