import { propEq } from "ramda";
import React, { useEffect } from "react";
import {
	bufferCount,
	concatAll,
	filter,
	pluck,
	skipUntil,
	Subject,
	tap,
	withLatestFrom,
} from "rxjs";
import {
	AmbientLight,
	BoxGeometry,
	DirectionalLight,
	Mesh,
	MeshBasicMaterial,
	MeshLambertMaterial,
	PCFSoftShadowMap,
	PerspectiveCamera,
	PlaneGeometry,
	PointLight,
	Scene,
	SphereGeometry,
	TextureLoader,
	WebGLRenderer,
} from "three";
import { OBJLoader } from "three/addons/loaders/OBJLoader";

const NFTBox = () => {
	useEffect(() => {}, []);

	let animate$ = new Subject();

	let selected_nft = "";

	let box_initialized = false;

	function SelectNFT(nft) {
		selected_nft = nft;
	}

	function buttonClicked() {
		SelectNFT("007.png");
		document
			.getElementById("nft_box_canvas")
			.classList.remove("nft_hidden");
		document.getElementById("nft_box_canvas").classList.add("nft_show");
		animate$.next(null);
		setTimeout(() => {
			document
				.getElementById("nft_box_canvas")
				.classList.remove("nft_hidden");
		}, 3000);
	}

	function InitBox() {
		if (box_initialized) return;
		box_initialized = true;
		let screenW;
		let y;
		
		if (window.innerWidth > 800) {
			screenW = 20;
			y = -0.5;
		}else if (window.innerWidth <= 450) {
			screenW = 35;
			y = 0;
		} else if (window.innerWidth <= 650) {
			screenW = 30;
			y = 0;
		} else if (window.innerWidth <= 800) {
			screenW = 25;
			y = 0;
		}

		const renderer_width = window.innerWidth;
		const renderer_height = window.innerHeight;
		const scene = new Scene();
		const camera = new PerspectiveCamera(
			screenW,
			renderer_width / renderer_height,
			2,
			64
		);
		camera.position.set(10, 2, 8);
		camera.lookAt(0, y, 0);

		const renderer = new WebGLRenderer({
			antialias: true,
		});

		renderer.shadowMap.enabled = true;
		renderer.shadowMap.type = PCFSoftShadowMap;

		renderer.setSize(renderer_width, renderer_height);
		document
			.getElementById("nft_box_canvas")
			.appendChild(renderer.domElement);

		const light = new AmbientLight(0x404040);
		scene.add(light);

		const light_sphere = new SphereGeometry(0.1, 4, 4);
		const point_light = new PointLight(0xffedae, 4, 64);
		point_light.position.y = 0.1;
		point_light.add(
			new Mesh(
				light_sphere,
				new MeshBasicMaterial({
					color: 0xffedae,
					transparent: true,
					opacity: 0,
				})
			)
		);
		scene.add(point_light);

		const directional_light = new DirectionalLight(0xffffff, 0.5);
		scene.add(directional_light);

		const pre_render$ = new Subject();
		const loader$ = new Subject();
		const assets$ = new Subject();
		const loaded$ = new Subject();
		const start_animation$ = new Subject();

		const loader = new OBJLoader();

		assets$
			.pipe(
				tap((e) => {
					e.material = new MeshLambertMaterial({
						color: 0xadadad,
					});
				})
			)
			.pipe(bufferCount(6))
			.pipe(tap((e) => scene.add(...e)))
			.subscribe();

		let side_1$ = assets$.pipe(filter(propEq("name", "side_1")));

		let side_2$ = assets$.pipe(filter(propEq("name", "side_2")));

		let side_3$ = assets$.pipe(filter(propEq("name", "side_3")));

		let side_4$ = assets$.pipe(filter(propEq("name", "side_4")));

		let top$ = assets$.pipe(filter(propEq("name", "top")));

		let bottom$ = assets$.pipe(filter(propEq("name", "bottom")));

		loader.load("box.obj", (e) => loader$.next(e));

		loader$
			.pipe(pluck("children"))
			.pipe(tap((e) => loaded$.next(e)))
			.pipe(concatAll())
			.pipe(tap((e) => assets$.next(e)))
			.subscribe();

		let speed = 0.058;

		loaded$
			.pipe(
				tap(() => {
					const geometry = new BoxGeometry(1.95, 1.95, 1.95);
					const material = new MeshBasicMaterial({
						color: 0xffedae,
					});

					const cube = new Mesh(geometry, material);
					scene.add(cube);

					start_animation$
						.pipe(
							tap(() => {
								scene.remove(cube);
							})
						)
						.subscribe();
				})
			)
			.subscribe();

		animate$
			.pipe(
				tap(() => {
					const texture = new TextureLoader().load(selected_nft);
					const plane_geometry = new PlaneGeometry(
						1.08 * 1.5,
						1.35 * 1.5
					);
					const plane_material = new MeshBasicMaterial({
						map: texture,
					});
					const plane = new Mesh(plane_geometry, plane_material);
					plane.rotation.y = 0.9;
					scene.add(plane);
					start_animation$.next(null);
				})
			)
			.subscribe();

		pre_render$
			.pipe(skipUntil(start_animation$))
			.pipe(withLatestFrom(side_1$))
			.pipe(
				tap(([t, e]) => {
					e.position.z += speed;
				})
			)
			.subscribe();

		pre_render$
			.pipe(skipUntil(start_animation$))
			.pipe(withLatestFrom(side_2$))
			.pipe(
				tap(([t, e]) => {
					e.position.z -= speed;
				})
			)
			.subscribe();

		pre_render$
			.pipe(skipUntil(start_animation$))
			.pipe(withLatestFrom(side_3$))
			.pipe(
				tap(([t, e]) => {
					e.position.x -= speed;
				})
			)
			.subscribe();

		pre_render$
			.pipe(skipUntil(start_animation$))
			.pipe(withLatestFrom(side_4$))
			.pipe(
				tap(([t, e]) => {
					e.position.x += speed;
				})
			)
			.subscribe();

		pre_render$
			.pipe(skipUntil(start_animation$))
			.pipe(withLatestFrom(top$))
			.pipe(
				tap(([t, e]) => {
					e.position.y += speed;
				})
			)
			.subscribe();

		pre_render$
			.pipe(skipUntil(start_animation$))
			.pipe(withLatestFrom(bottom$))
			.pipe(
				tap(([t, e]) => {
					e.position.y -= speed;
				})
			)
			.subscribe();

		function animate(t) {
			pre_render$.next(t);
			requestAnimationFrame(animate);
			renderer.render(scene, camera);
		}

		animate();
	}

	useEffect(() => {
		InitBox();
		setTimeout(
			() => buttonClicked(),


			1000
		);
	}, []);

	return <></>;
};

export default NFTBox;
