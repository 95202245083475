import NFT2 from "../../img/nft2.png";
import NFT3 from "../../img/nft3.png";
import NFT4 from "../../img/nft4.png";
import NFT5 from "../../img/nft5.png";
import NFT6 from "../../img/nft6.png";
import NFT8 from "../../img/nft8.png";
import NFT9 from "../../img/nft9.png";
import NFT from "../../img/nfttest.png";


import NFT20 from "../../img/007.png";
import NFT21 from "../../img/016.png";
import NFT22 from "../../img/031.png";
import NFT23 from "../../img/045.png";
import NFT24 from "../../img/053.png";
import NFT25 from "../../img/063.png";
import NFT26 from "../../img/065.png";
import NFT27 from "../../img/101.png";
import NFT28 from "../../img/104.png";
import NFT29 from "../../img/129.png";
import NFT36 from "../../img/238.png";


const data = {
	cardData: [

        {
            id: 1,
            img: NFT,
        },
        {
            id: 2,
            img: NFT2,
        },
        {
            id: 3,
            img: NFT3,
        },
        {
            id: 4,
            img: NFT4,
        },
        {
            id: 5,
            img: NFT5,
        },
        {
            id: 6,
            img: NFT6,
        },
        {
            id: 7,
            img: NFT8,
        },
        {
            id: 8,
            img: NFT9,
        },
        {
            id: 9,
            img: NFT36,
        },
        {
            id: 10,
            img: NFT21,
        },
        {
            id: 11,
            img: NFT22,
        },
        {
            id: 12,
            img: NFT23,
        },
        {
            id: 13,
            img: NFT24,
        },
        {
            id: 14,
            img: NFT25,
        },
        {
            id: 15,
            img: NFT28,
        },
        {
            id: 16,
            img: NFT29,
        },


    ],

	purchased_nft: [
		{
			id: 1,
			img: NFT20,
		},
		{
			id: 2,
			img: NFT21,
		},
		{
			id: 3,
			img: NFT22,
		},
		{
			id: 4,
			img: NFT23,
		},
		{
			id: 5,
			img: NFT24,
		},
		{
			id: 6,
			img: NFT25,
		},
		{
			id: 7,
			img: NFT36,
		},
		{
			id: 8,
			img: NFT26,
		},
		{
			id: 9,
			img: NFT27,
		},
		{
			id: 10,
			img: NFT28,
		},
		{
			id: 11,
			img: NFT29,
		}

	]
}
export default data
