import styled from "styled-components";

export const DivAppBoxSC = styled.div`
	height: 100%;
`;

export const DivAppRelativeBoxSC = styled.div`
	height: 100%;
	position: relative;
	display: grid;
`;

export const DivBubblesBoxSC = styled.div`
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 100;
`;

export const DIvSHowOverlayWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: black;
	opacity: 0.1;
	color: white;
`;

