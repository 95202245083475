import React, { useContext, useState, useEffect } from "react";

import {
	DivBoxSC,
	GridRowsSC,
	BackgroundGradientSC,
	P1TextSC,
	DivBackgroundCardImagesSC,
	DivCardsImagesSC,
	ButtonCustomSC,
	PTextButtonSC,
	ButtonBackSC,
	PTextTitleSC,
	DivUtilityImagesSC,
	DivUtilityImageSC,
	DivRegularCardSC,
	TitleRegularCardSC,
	OlRegularCardSC,
	DivRegularCardSC2,
	OlRegularCard2SC,
} from "../../styled-components/styled.section2";
import "./Carousel_Cards";
import "./Carousel_Cards.css";
import "../section2/Carousel_2.css";
import Image_NFT1 from "../../img/007.png";
import Image_NFT2 from "../../img/Section2_NFT/NFTutility.png";
import Card_Carousel from "./Carousel_Cards";
import GlobalDispatchContext from "../../global_dispatch_context";
import "./CardEvent.css";
// import "./timeline.scss";
const SectionsBox2 = (props) => {
	const [onMouseEnter1, setOnMouseEnter1] = useState(true);
	const [onMouseEnter2, setOnMouseEnter2] = useState(true);
	const { state, dispatch } = useContext(GlobalDispatchContext);
	const { isOpen } = state;
	const onClickCards = (e) => {
		// const carouselList = document.querySelector('.carousel__list');
	};

	return (
		<div>
			<div className="center-it-all">
				<P1TextSC>
					Exclusive VIP access to private events during Art Basel
					2022.
				</P1TextSC>
				{/*<Card_Carousel />*/}
				<ButtonCustomSC href="/gallery">
					<ButtonBackSC>
						<PTextButtonSC>View more</PTextButtonSC>
					</ButtonBackSC>
				</ButtonCustomSC>
			</div>
			<div classsName="marginmovebot">
				<GridRowsSC>
					<BackgroundGradientSC></BackgroundGradientSC>

					<PTextTitleSC>
						Utility for Art Basel by NFT Rarity
					</PTextTitleSC>
					<DivUtilityImagesSC>
						<div class="container">
							{/* <div class="card">
							<div class="face face1">
								<div class="content">
									<span class="stars"></span>
									<h2 class="java">Java</h2>
									<p class="java">Java is a class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible.</p>
								</div>
							</div>
							<div class="face face2">
								<h2>01</h2>
							</div>
						</div> */}

							<div class="card">
								<DivRegularCardSC>
									<TitleRegularCardSC margin={"0px"}>
										Regular
									</TitleRegularCardSC>
									<OlRegularCardSC>
										<li>
											Access to Epic Pool Parties at the
											Sagamore all week
										</li>
										<ul>
											<li>
												Includes access to VIP Modern
												Art Theory Clubhouse Art
												Exposition ft. Special Guests
											</li>
											<li>
												POAP (proof of attendance
												protocol)
											</li>
											<li>
												Feat. music by Invisible People
												(Antennae side project)
											</li>
										</ul>
										<li>
											Access pass to Elemental (valid for
											the entire week)
										</li>
										<ul>
											<li>Includes access to MAT</li>
										</ul>
										<li>
											Access pass to The Loop on Saturday
											Night
										</li>
										<li>
											Discounted passes to all Ticketfairy
											Events, including Rakastella
										</li>
										<li>
											Additional access and amenities at
											our future events
										</li>
										<h2 className="center">
											Total value of all perks and event
											access is 1595$
										</h2>
									</OlRegularCardSC>
								</DivRegularCardSC>
								{/* <div class="face face2">
								{!isOpen ? (
									<>
										<div class="click">
											<div className="blur" />
											HOLD ME FOR MORE INFO
										</div>
									</>
								) : null}
							</div> */}
							</div>

							<div class="card">
								<DivRegularCardSC2>
									<TitleRegularCardSC
										color={"black"}
										margin={"18px"}
									>
										Gold
									</TitleRegularCardSC>
									<OlRegularCardSC color={"black"}>
										<li>Access to all regular features</li>
										<li>Access to Rakastella</li>
										<li>VIP access to Elemental</li>
										<li>VIP access to MAT event</li>
										<li>VIP access to NFT Now</li>
										<li>VIP access to MAT Clubhouse</li>
										<li>
											VIP access to exclusive afterhours
											event Saturday night
										</li>
										<h2 className="center">
											Total value of all VIP perks and
											event access is 9240$
										</h2>
									</OlRegularCardSC>
								</DivRegularCardSC2>
								{/* <div class="face face2">
								{!isOpen ? (
									<>
										<div class="click">
											<div className="blur" />
											HOLD ME FOR MORE INFO
										</div>
									</>
								) : null}
							</div> */}
							</div>
						</div>
						{/* <div className="card">
						<div className="card__side card__side--back">
							<DivUtilityImageSC
								UtilityImage={Image_NFT1}
								hover={onMouseEnter1}
								// onMouseLeave={() => setOnMouseEnter1(false)}
								// onMouseEnter={() => setOnMouseEnter1(true)}
								color={"#0F0F0F"}
							></DivUtilityImageSC>
						</div>

						<div className="card__side card__side--front">
							<DivUtilityImageSC>
								<DIvSHowOverlayWrapper>
									<DivRegularCardSC>
										<TitleRegularCardSC margin={"0px"}>
											Regular
										</TitleRegularCardSC>
										<OlRegularCardSC>
											<li>
												Main Event at Modern Art Theory
												Tuesday (exclusive to NFT
												holders)
											</li>
											<ul>
												<li>Open bar</li>
												<li>
													POAP (proof of attendance
													protocol)
												</li>
												<li>
													Feat. music by Invisible
													People (Antennae side
													project)
												</li>
											</ul>
											<li>
												Access to MAT Clubhouse at the
												Sagamore all week
											</li>
											<ul>
												<li>
													Includes access to Epic pool
													parties all week
												</li>
											</ul>
											<li>Access pass to Elemental</li>
											<li>
												Access pass to an exclusive
												afterhours event Saturday night
												(Lineup TBA)
											</li>
											<li>
												Discounted pass to all
												Ticketfairy events, including
												Rakastella
											</li>
											<li>Access to Floyd on Tuesday</li>
										</OlRegularCardSC>
									</DivRegularCardSC>
								</DIvSHowOverlayWrapper>
							</DivUtilityImageSC>
						</div>
					</div>

					<div className="card">
						<div className="card__side card__side--back">
							<DivUtilityImageSC
								UtilityImage={Image_NFT2}
								hover={onMouseEnter2}
								// onMouseLeave={() => setOnMouseEnter2(false)}
								// onMouseEnter={() => setOnMouseEnter2(true)}
								color={"#FFD234"}
							></DivUtilityImageSC>
						</div>

						<div className="card__side card__side--front">
							<DivRegularCardSC2>
								<TitleRegularCardSC
									color={"black"}
									margin={"18px"}
								>
									Gold
								</TitleRegularCardSC>
								<OlRegularCardSC color={"black"}>
									<li>Access to all regular features</li>
									<li>Access to Rakastella</li>
									<li>VIP access to Elemental</li>
									<li>VIP access to MAT event</li>
									<li>VIP access to NFT Now</li>
									<li>VIP access to MAT Clubhouse</li>
									<li>
										VIP access to exclusive afterhours event
										Saturday night
									</li>
								</OlRegularCardSC>
							</DivRegularCardSC2>
						</div>
					</div> */}
					</DivUtilityImagesSC>
				</GridRowsSC>
				<PTextTitleSC>
					How to gain access to the events with your NFT?
				</PTextTitleSC>
				<div className="step-wrap">
					<div class="a-box">
						<div class="img-container">
							<div class="img-inner">
								<div class="inner-skew">
									<img
										src={
											window.location.origin +
											"/StepImages/step1.png"
										}
									/>
								</div>
							</div>
						</div>
						<div class="text-container">
							<h3>Download Metamask</h3>
							<div>
								Download and fund your metmask account with at
								least 0.5 ethereum
								<b>
									{" "}
									connect your wallet on the METAMASK BROWSER
								</b>
								, and open a card to mint your unique NFT.
							</div>
						</div>
					</div>
					<div class="a-box">
						<div class="img-container">
							<div class="img-inner">
								<div class="inner-skew">
									<img
										src={
											window.location.origin +
											"/StepImages/2.png"
										}
									/>
								</div>
							</div>
						</div>
						<div class="text-container">
							<h3>Arrive and show NFT from Wallet</h3>
							<div>
								Simply arrive at the event and present your
								metamask wallet with the NFT to gain access to
								the event! Note: access for NFT holders is
								dependent on capacity at the event. We recommend
								arriving early to guarantee access!
							</div>
						</div>
					</div>
					<div class="a-box">
						<div class="img-container">
							<div class="img-inner">
								<div class="inner-skew">
									<img
										src={
											window.location.origin +
											"/StepImages/3.png"
										}
									/>
								</div>
							</div>
						</div>
						<div class="text-container">
							<h3>Reach out for any questions or help</h3>
							<div>
								If you have any trouble or need further
								assistance, please feel free to email
								anders@modernarttheory.io
							</div>
						</div>
					</div>
				</div>
				<PTextTitleSC>Click to View Venues And Events</PTextTitleSC>
				<div class="card-events-list">
					<a
						href="https://www.elemental.miami/"
						className="flex-base-third"
						target="_blank"
					>
						<div class="card-events 1">
							<div class="card-events_image">
								{" "}
								<img
									src={
										window.location.origin +
										"/EventImages/elemental.png"
									}
								/>{" "}
							</div>
							<div class="card-events_title title-white">
								<p>Elemental</p>
							</div>
						</div>
					</a>
					<a
						href="https://www.floydmiami.com/"
						className="flex-base-third"
						target="_blank"
					>
						<div class="card-events 1">
							<div class="card-events_image">
								{" "}
								<img
									src={
										window.location.origin +
										"/EventImages/floyd.jpg"
									}
								/>{" "}
							</div>
							<div class="card-events_title title-white">
								<p>Floyd</p>
							</div>
						</div>
					</a>
					<a
						href="https://www.rakastella.com/"
						className="flex-base-third"
						target="_blank"
					>
						<div class="card-events 1">
							<div class="card-events_image">
								{" "}
								<img
									src={
										window.location.origin +
										"/EventImages/rakastella.jpg"
									}
								/>{" "}
							</div>
							<div class="card-events_title title-white">
								<p>Rakastella</p>
							</div>
						</div>
					</a>
					<a
						href="https://theepicpoolparty.com/"
						className="flex-base-third"
						target="_blank"
					>
						<div class="card-events 1">
							<div class="card-events_image">
								{" "}
								<img
									src={
										window.location.origin +
										"/EventImages/Sagamore.jpg"
									}
								/>{" "}
							</div>
							<div class="card-events_title title-white">
								<p>Sagamore</p>
							</div>
						</div>
					</a>
					<a
						href="https://www.theloopevents.org/new-events"
						target="_blank"
						className="flex-base-third"
					>
						<div class="card-events 1">
							<div class="card-events_image">
								{" "}
								<img
									src={
										window.location.origin +
										"/EventImages/theloop.jpg"
									}
								/>{" "}
							</div>
							<div class="card-events_title title-white">
								<p>The Loop</p>
							</div>
						</div>
					</a>
					<a
						href="https://nftnow.com/"
						className="flex-base-third"
						target="_blank"
					>
						<div class="card-events 1">
							<div class="card-events_image">
								{" "}
								<img
									src={
										window.location.origin +
										"/EventImages/nftnow.png"
									}
								/>{" "}
							</div>
							<div class="card-events_title title-white">
								<p>NFT Now</p>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	);
};

export default SectionsBox2;
