import { FiFacebook, FiInstagram, FiMail, FiTwitter } from "react-icons/fi";
import LogoWhite from "../img/LogoWhite.svg";
import styled from "styled-components";
import Logo from "../img/LogoCyberZen.svg";

export const DivFooterBoxSC = styled.div`
	height: 250px;
	background: #0f0f0f;
	z-index: 10;
	@media (max-width: 800px) {
		height: 150px;
	}
`;
export const LogoSC = styled.div`
	background-image: url(${LogoWhite});
	width: 162px;
	height: 47px;
	background-size: 100%;
	background-repeat: no-repeat;
	@media (max-width: 1000px) {
		width: 120px;
		height: 40px;
	}
	@media (max-width: 550px) {
		width: 100px;
		height: 40px;
	}
`;

export const DivContentBoxSC = styled.div`
	margin: 0 auto;
	max-width: 1420px;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 4fr 1fr;
	@media (max-width: 800px) {
		align-content: space-between;
		grid-template-rows: 5fr 5fr 2fr;
		grid-row-gap: 0;
	}
	justify-items: center;
`;
export const DivInstagramBoxSC = styled.div`
	height: max-content;
	width: max-content;
	display: grid;
	grid-template-columns: max-content max-content;
	column-gap: 9px;
	align-items: center;
	@media (max-width: 1000px) {
		column-gap: 4px;

	}
`;

export const DivSocialNetworkBoxSC = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	justify-items: center;
	/* column-gap: 20%; */
`;

export const DivSocialNetworkSC = styled.div`

	width: clamp(360px, 100%, 700px);
	height: 100%;
	display: grid;
	grid-template-columns: repeat(4, max-content);
	/* justify-items: center; */
	align-items: center;
	justify-content: center;
	/* column-gap: 10%; */
	column-gap: 84px;
	@media (max-width: 1420px) {
		column-gap: 40px;

	}
	@media (max-width: 1000px) {
		column-gap: 3%;
	}
	@media (max-width: 800px) {
		grid-template-columns: repeat(3, max-content);
		column-gap: 6%;
	}

	@media (max-width: 515px) {
		column-gap: 4%;
	}
`;

export const LinkSocialNetworkSC = styled.a`
	cursor: pointer;
	width: max-content;
	height: max-content;
	background-repeat: no-repeat;
	background-size: 100%;
	/* text-decoration: none; */
	
`;

export const IconLinkFacebookSC = styled(FiFacebook)`
	color: #fff;
	width: 60px;
	height: 70px;
	@media (max-width: 768px) {
		width: 40px;
		height: 50px;
	}
	@media (max-width: 480px) {
		width: 30px;
		height: 40px;
	}
`;

export const IconLinkTwitterSC = styled(FiTwitter)`
	color: #fff;
	width: 60px;
	height: 70px;
	@media (max-width: 768px) {
		width: 40px;
		height: 50px;
	}
	@media (max-width: 480px) {
		width: 30px;
		height: 40px;
	}
`;

export const IconLinkInstagramSC = styled(FiInstagram)`
	color: #fff;
	width: 60px;
	height: 70px;
	@media (max-width: 1000px) {
		width: 40px;
		height: 50px;
	}
	@media (max-width: 480px) {
		width: 30px;
		height: 40px;
	}
`;

export const DivMailBoxSC = styled.div`
	height: max-content;
	width: max-content;
	display: grid;
	grid-template-columns: max-content max-content;
	column-gap: 9px;
	align-items: center;
	@media (max-width: 1000px) {
		column-gap: 4px;

	}
`;

export const H1MailBoxSC = styled.p`
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #fff;
	@media (max-width: 800px) {
		font-size: 12px;
		line-height: 12px;
	}
	@media (max-width: 480px) {
		font-size: 8px;
		line-height: 10px;
	}
`;

export const IconLinkMailSC = styled(FiMail)`
	color: #fff;
	width: 60px;
	height: 70px;
	@media (max-width: 1000px) {
		width: 40px;
		height: 50px;
	}
	@media (max-width: 480px) {
		width: 30px;
		height: 40px;
	}
`;

export const DivCopyrightBoxSC = styled.div`
	height: max-content;
	width: max-content;
`;
export const DivAdaptivePC = styled.div`
	@media (max-width: 600px) {
		display: none;
	}
`;
export const DivAdaptivePhone = styled.div`
	display: none;
	@media (max-width: 600px) {
		display: flex;
	}
`;
export const H1CopyrightBoxSC = styled.p`
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	color: #e8e8e8;
	margin: 0;
	@media (max-width: 800px) {
		font-size: 12px;
	}
	@media (max-width: 480px) {
		font-size: 10px;
	}
`;

export const DivMadeByBoxSC = styled.div`
height: max-content;
	width: max-content;
	display: grid;
	grid-template-columns: max-content max-content;
	column-gap: 9px;
	align-items: center;
	@media (max-width: 1000px) {
		column-gap: 4px;
	}
	@media (max-width: 800px) {
		display:none;
	}
`;
export const DivMadeByBoxAdaptiveSC = styled.div`
display:none;
@media (max-width: 800px) {
	
	height: 100%;
		width: max-content;
		display: grid;
		grid-template-columns: max-content max-content;
		column-gap: 9px;
		align-items: center;
		align-content: center;

}
	
`;
export const DivMadeByLogoSC = styled.div`
background-image: url(${Logo});
    background-repeat: no-repeat;
    width: 48px;
    height: 50px;
    background-size: 200%;
    display: grid;
    background-position: center;
	cursor: pointer;
    @media (max-width: 1000px) {
      width: 38px;
    height: 40px;
  }
`;
export const GridTextMadeBySC = styled.div`
display: grid;
align-content: center;
`;

export const PTextMadeBySC = styled.p`
margin: 0;
font-family: 'Orbitron';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
justify-self: start;
color: #fff;

@media (max-width: 800px) {
  font-size: 10px;
line-height: 12px;
  }
  @media (max-width: 480px) {
  font-size: 8px;
line-height: 10px;
  }
`;

export const PTextCompanySC = styled.p`
margin: 0;
font-family: 'Orbitron';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;

color: #fff;

@media (max-width: 800px) {
  font-size: 12px;
line-height: 14px;
  }

  @media (max-width: 480px) {
  font-size: 10px;
line-height: 12px;
  }
`;