import React, { useContext, useState, useEffect } from "react";
import { FaEthereum } from "react-icons/fa";
import {
	ButtonSC,
	DivCircleGradientSC,
	DivInnerBoxTwoColumnsSC,
	DivNFTBlockSC,
	DivNFTBoxButtonSC,
	DivNFTBoxSC,
	DivNFTTextSC,
	DivS4BoxSC,
	StyledFaethereum,
	TextButtonSC,
} from "../../styled-components/styled.section4";
import { ethers } from "ethers";
import { CoinbaseWalletSDK } from "@coinbase/wallet-sdk";
import Web3Modal from "web3modal";
import { getContract } from "../../utils/interact";
import GlobalDispatchContext from "../../global_dispatch_context";
import Modal from "./Modal";
import abi from "../../contract/abi.json";
const providerOptions = {
	coinbasewallet: {
		package: CoinbaseWalletSDK,
		options: {
			appName: "Modern Art Theory",
			infuraId: {
				5: "https://sepolia.infura.io/v3/dbf8d412f1c5482191cd048033486279",
			},
		},
	},
};

const SectionBox4 = (props) => {
	const { bottomRefBox, onClickButtonHome } = props;

	const [maxSupply, setMaxSupply] = useState(0);
	const [totalMinted, setTotalMinted] = useState(0);
	const [maxMintAmount, setMaxMintAmount] = useState(0);
	const [paused, setPaused] = useState(false);
	const [isPublicSale, setIsPublicSale] = useState(false);
	const [isPreSale, setIsPreSale] = useState(false);

	const [status, setStatus] = useState(null);
	const [mintAmount, setMintAmount] = useState(1);
	const [isMinting, setIsMinting] = useState(false);
	const [onboard, setOnboard] = useState(null);
	const [web3Provider, setWeb3Provider] = useState(null);

	useEffect(() => {
		if (localStorage.getItem("walletAddress")) {
			getData(localStorage.getItem("walletAddress"));
		}
	}, []);
	const { state, dispatch } = useContext(GlobalDispatchContext);
	const { isOpen } = state;

	const buyNft = () => {
		if (web3Provider) {
			const daiContract = new ethers.Contract(
				"0x3698Fee81a9dcB039634c7C7b0332a458D1ac2d8",
				abi,
				web3Provider
			);
			const signer = web3Provider.getSigner();
			const daiContractWithSigner = daiContract.connect(signer);
			getContract(daiContractWithSigner);
		}
	};
	console.log("><>>>><><", Math.floor(Math.random() * 251));

	async function getData(address) {
		if (window.ethereum) {
			try {
				let web3Modal = new Web3Modal({
					cacheProvider: true,
					providerOptions,
				});
				const web3ModalInstance = await web3Modal.connect(address);
				const web3ModalProvider = new ethers.providers.Web3Provider(
					web3ModalInstance
				);
				// const web3 = new Web3(web3ModalInstance);
				// const accounts = await web3.eth.getAccounts();
				if (web3ModalProvider) {
					setWeb3Provider(web3ModalProvider);
				}
			} catch (err) {
				console.log(err);
			}
		}
	}

	return (
		<>
			<div ref={bottomRefBox}></div>
			<DivS4BoxSC>
				<DivInnerBoxTwoColumnsSC>
					<DivNFTBlockSC>
						<DivNFTBoxSC></DivNFTBoxSC>
						<DivNFTBoxButtonSC>
							<ButtonSC
								id="open_nft_box"
								onClick={() => onClickButtonHome()}
							>
								<TextButtonSC>
									Open a card for 0.5 <StyledFaethereum />
								</TextButtonSC>
							</ButtonSC>
						</DivNFTBoxButtonSC>
					</DivNFTBlockSC>
					<DivNFTTextSC>
						<DivCircleGradientSC />
						Our initial drop of 250 NFTs is more than just a unique
						individually-crafted piece of digital art, it is your
						access pass to some of the most exclusive events during
						Art Basel. 12 lucky holders will also receive a rare
						gold NFT, providing additional VIP access as well as
						custom branded merchandise.
					</DivNFTTextSC>
				</DivInnerBoxTwoColumnsSC>
			</DivS4BoxSC>
			<Modal open={isOpen} />
		</>
	);
};

export default SectionBox4;
