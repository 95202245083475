import styled from "styled-components/macro";


export const DivContentSC = styled.div`

width: 100%;
`;

export const DivBoxMyNFTs = styled.div`
  width: 100%;

  
  background-color: initial;
  //padding: 50px 30px 50px 30px;
  //max-width: var(--max-width);

  //background-color: var(--color-dark);
  /* background: radial-gradient(
    38.73% 34.73% at 45.22% 50.27%,
    rgb(95 4 12 / 87%) 0%,
    rgba(17, 17, 17) 100%
  ); */
  /* @media (max-width: 1600px) {
    padding: 50px 10px 50px 10px;
  } */
`;


export const DivNFT = styled.div`
position: relative;
background-repeat:no-repeat;
max-width: 340px;
max-height: 430px;
height: 100%;
aspect-ratio: 340/430;
  background-size: 100%;
  background-image: ${({ Photo }) => Photo && `url(${Photo})`};


`;
export const DivContainerMyNFTsSC = styled.div`
  max-width: var(--max-width);
  height: 100%;
  margin: 100px auto;
  margin-top: 50px;
  width: 95vw;
  justify-content: center;
`;

export const DivBoxRowCollectionsSC = styled.div`
  user-select: none;
  row-gap: 36px;
  grid-template-rows: max-content max-content;
  display: grid;
  align-content: center;
  align-items: center;
  user-select: none;
  justify-items: center;
`;
export const DivTextColectionSC = styled.div`
  font-family: "SpecifyPersonalExpandedBlack-J1do";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 120%;
  @media (max-width: 540px) {
    font-size: 48px;
  }
  color: black;
  width: 100%;
  max-width: 1420px;
`;
export const DivContainerGridCollectionSC = styled.div`
  width: 100%;
  height: 100%;
justify-content: center;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 20px;

  align-self: end;
  @media (max-width: 990px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 730px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 480px) {
    grid-column-gap: 10px;
  grid-row-gap: 14px;
  }
  max-width: 1420px;

`;

