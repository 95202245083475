import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import MainGallery from "./components/gallery-page";
import MyNFTs from "./components/MyNFTs";
import RootBox from "./components/RootBox";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<RootBox>
					<Routes>
						<Route index element={ <App/> }/>
						<Route path="gallery" element={ <MainGallery/> }/>
						<Route path="mynfts" element={ <MyNFTs/> }/>

					</Routes>
					{/* <Footer /> */ }
				</RootBox>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);

reportWebVitals();
