import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../src/components/gallery-page/NavbarGallery";
import { } from "../styled-components/styled.MyNFTs";
import {
	DivBoxRowCollectionsSC,
	DivContainerMyNFTsSC,
	DivNFT,
	DivContentSC,
	DivBoxMyNFTs,
	DivTextColectionSC,
	DivContainerGridCollectionSC,
} from "../styled-components/styled.MyNFTs";
import Footer from "../components/section6";

import { } from "../styled-components/styled.section5.jsx";
import data from "../components/section5/data";
import GlobalDispatchContext from "../global_dispatch_context";

const MyNFTs = (props) => {
	const { state, dispatch } = useContext(GlobalDispatchContext);
	const { isBubbles } = state;


	const [noOfElement, setnoOfElement] = useState(16);
	const slice = data.purchased_nft.slice(0, noOfElement);
	const num = 0;




	return (
		<>
			<Navbar />
			<DivBoxMyNFTs>
				<DivContainerMyNFTsSC>
					<DivBoxRowCollectionsSC>
						<DivTextColectionSC>My NFT</DivTextColectionSC>

						<DivContainerGridCollectionSC>
							{slice.map((item, index) => {
								return <DivNFT Photo={item.img}></DivNFT>;
							})}
						</DivContainerGridCollectionSC>
					</DivBoxRowCollectionsSC>
				</DivContainerMyNFTsSC>
			</DivBoxMyNFTs>
			<Footer />
		</>
	);
};
export default MyNFTs;
