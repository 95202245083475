const setWalletAdress = (state, action) => {
	const { status } = action;

	return {
		...state,
		walletAdress: status,
	};
};
const setIsOpen = (state, action) => {

	const { status } = action;

	return {
		...state,
		isOpen: status,
	};
};
const setIsBubbles = (state, action) => {
	const { status } = action;

	return {
		...state,
		isBubbles: status,
	};
};

export default function (state, action) {
	switch (action.type) {
		case "SET_WALLET_ADRESS":
			return setWalletAdress(state, action);

		case "SET_IS_OPEN":
			return setIsOpen(state, action);
		case "SET_IS_BUBBLES":
			return setIsBubbles(state, action);

		default:
			return state;
	}
}
